import React from "react";

const CurrencyInr = ({ value }: any) => {
  if (typeof value === "string" && value.includes(",")) {
    return <span>₹{value}</span>;
  }

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const fraction = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return (
    <span>
      {parseFloat(value) % 1 == 0
        ? fraction.format(parseFloat(value))
        : formatter.format(parseFloat(value))}
    </span>
  );
};

export default CurrencyInr;
