export const headerTopLeftLinks = [
  {
    props: {
      href: "https://wa.me/7605023222",
      target: "_blank",
      rel: "noopener noreferrer",
    },
    image: require("@/assets/images/whatsapp.png"),
    alt: "WhatsApp Icon",
    label: "7605023222",
  },
  {
    props: {
      href: "tel://18001030017",
    },
    image: require("@/assets/images/head-phone.png"),
    alt: "Phone Icon",
    label: "18001030017",
  },
  {
    props: {
      href: "/store-locator",
    },
    image: require("@/assets/images/map.png"),
    alt: "Map Icon",
    label: "Find Store",
  },
];
