import { fbqTrackEvent } from "@/lib/fpixel";
import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import { selectCart } from "@/store/slices/cart";
import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
// import logo from "../../../assets/images/logo-white.png";
import searchiconn from "../../../assets/images/squiggly-arrow.png";
import carda from "../../../assets/images/p1.png";
import React, { Fragment, useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import styles from "@/styles/mobile_header.module.scss";
import { useSelector } from "react-redux";
import searchicon from "../../../../public/img/search.png";
import maincart from "../../../assets/images/cart-main.png";
import headphone from "../../../assets/images/headphone.png";
import logo from "../../../assets/images/logo-pop-new1.png";
import user from "../../../assets/images/user.png";
import wish from "../../../assets/images/wish.png";
import { menuList } from "@/data/menu";
import { useRouter } from "next/router";
import { selectWishlist } from "@/store/slices/wishlist";
import Marquee from "react-easy-marquee";
import { useDebounce } from "usehooks-ts";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import CurrencyInr from "@/components/common/currency-inr";
SwiperCore.use([Navigation, Pagination]);
type PageProps = {
  menus: any[];
};

const MobileHeader = ({ menus }: PageProps) => {
  const dispatch = useAppDispatch();
  const cartState = useSelector(selectCart);
  const wishlistState = useSelector(selectWishlist);
  const authState = useSelector(selectAuth);
  const [navOpen, setNavOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const router = useRouter();
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState<any>(null);
  const debouncedValue = useDebounce<string>(search, 500);
  const [loadbox, setLoadbox] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const searchSubmit = (e: any) => {
    e.preventDefault();
    if (search.trim()) {
      fbqTrackEvent("Search", { search_string: search });
      router.replace(
        {
          pathname: "/jewellery",
          query: {
            q: search,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const fetchData = async (value: any) => {
    if (value?.length && loadbox) {
      try {
        const { data } = await httpClient.post(
          endpoints.products.autoComplete(),
          {
            q: value,
            productsCount: 5,
          },
          {
            headers: {
              "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
              "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
            },
          }
        );
        setSearchData(data.payload);
      } catch (error: any) {
        setSearchData(null);
      }
    } else {
      setSearchData(null);
    }
  };
  useEffect(() => {
    fetchData(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (router.query.q) {
      setSearch(router.query.q as string);
    } else {
      setSearch("");
    }
    setLoadbox(false);
  }, [router.query]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 130) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      {!isFixed && (
        <div className={styles.mobile_header_view}>
          <div className={`${styles.top_mobile_header} ${"pb-0"}`}>
            {false && (
              <Marquee duration={15000} height="20px" reverse>
                <p
                  className="mb-0 mx-5 top-text-topbar"
                  style={{ color: "#000" }}
                >
                  Enjoy Rs. 500 off on your first purchase. Sign Up Now! *T&C
                  applicable
                  {/* <strong>
                We are upgrading our site for a better experience, you might
                face some technical glitches. We apologize for any inconvenience
                this may cause. Thank you for your patience.
              </strong> */}
                  {/* Our website is currently undergoing maintenance. Some links might
              not work for the next 2-3 hours. If you experience any
              difficulties, please contact customer support. Thank you for your
              understanding. */}
                </p>
              </Marquee>
            )}
          </div>
        </div>
      )}
      {/* add fixed */}
      <div className={`${styles.mobile_header_view}  ${styles.fixed}`}>
        <div className={styles.top_mobile_header}>
          <div className="container">
            <div className="row align-items-center">
              {/* <div className="col-3">
                <div className={styles.dropdown}>
                  <button role="button" className={styles.dropbtn}>SENCO</button>
                </div>
              </div> */}

              <div className="col-12">
                <div className={styles.buy_icon}>
                  <div className={styles.icon_first}>
                    <Link href="https://wa.me/7605023222">
                      <a target="_blank">
                        <Image
                          src={require("@/assets/images/whatsapp_m.png")}
                          className="img-fluid"
                          alt="Whatsapp"
                        />
                      </a>
                    </Link>
                  </div>

                  <div className={styles.icon_first}>
                    <Link href="tel://18001030017">
                      <a target="_blank">
                        <Image
                          src={headphone}
                          className="img-fluid"
                          alt="Call us"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className={styles.icon_first}>
                    <Link href="/store-locator">
                      <a className={styles.map_w}>
                        <Image
                          src={require("@/assets/images/map.png")}
                          className="img-fluid"
                          alt="Our Store"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className={styles.icon_first}>
                    <Link href="/wishlist">
                      <a>
                        <Image
                          src={wish}
                          className="img-fluid"
                          alt="Wishlist"
                        />
                        {!!wishlistState.items.length && (
                          <div className={styles.top_card_card}>
                            {wishlistState.items.length}
                          </div>
                        )}
                      </a>
                    </Link>
                  </div>
                  <div className={styles.icon_first}>
                    <Link href="/cart">
                      <a>
                        <Image
                          src={maincart}
                          className="img-fluid"
                          alt="cart"
                        />
                        {!!cartState.items.length && (
                          <div className={styles.top_card_card}>
                            {cartState.items.length}
                          </div>
                        )}
                      </a>
                    </Link>
                  </div>
                  <div className={styles.icon_first}>
                    {authState.user ? (
                      <Link href={"/account/profile"}>
                        <a>
                          <Image src={user} className="img-fluid" alt="User" />
                        </a>
                      </Link>
                    ) : authState.isUserLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span
                        onClick={() =>
                          dispatch(authActions.setIsModalOpen(true))
                        }
                      >
                        <Image src={user} className="img-fluid" alt="User" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.down_header}`}>
          <div className="container">
            <div className={styles.header_container}>
              <div className={styles.nav_icon}>
                <button
                  aria-label="nav Open"
                  role="button"
                  onClick={() => setNavOpen(!navOpen)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div className={styles.middle_logo}>
                <Link href="/">
                  <a>
                    <Image src={logo} className="img-fluid" alt="Logo" />
                  </a>
                </Link>
              </div>
              <div
                className={styles.search_mobile}
                onClick={() => setSearchOpen(!searchOpen)}
              >
                <Image
                  src={require("@/assets/images/search.png")}
                  className="img-fluid"
                  alt="search"
                  priority
                  unoptimized
                />
              </div>
              {searchOpen && (
                <form className={styles.search_box} onSubmit={searchSubmit}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={async (e) => {
                      setSearch(e.target.value);
                      setLoadbox(true);
                    }}
                  />
                  <button
                    type="button"
                    role="button"
                    className="--syte-start-camera-upload"
                    style={{ borderRight: "none" }}
                    data-camera-button-placement="search-bar"
                  >
                    <i
                      className="bi bi-camera"
                      style={{ fontSize: 16, color: "#FF1A32" }}
                    ></i>
                  </button>
                  <button
                    role="button"
                    aria-label="Search data"
                    className={styles.search_button}
                    type="submit"
                  >
                    <div
                      className={styles.icon}
                      onClick={() => {
                        setSearchData(null);
                        setLoadbox(false);
                        setSearch("");
                      }}
                    >
                      {!search.length ? (
                        <Image
                          className="img-fluid"
                          src={require("@/assets/images/searchicon.png")}
                          alt="search"
                          priority
                          unoptimized
                        />
                      ) : (
                        <Image
                          className={`${styles.crosss} ${"img-fluid"}`}
                          src={require("@/assets/images/cross-sign.png")}
                          alt="cross"
                          priority
                          unoptimized
                        />
                      )}
                    </div>
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
        {navOpen && (
          <>
            <div className={styles.slide_nav}>
              <div className={styles.user_profile}>
                <div className={styles.User_images}>
                  <Image
                    className="img-fluid"
                    src={require("@/assets/images/senco-logo-main.png")}
                    alt="Logo"
                    priority
                  />
                </div>
                {authState.user ? (
                  <Link href={"/account/profile"}>
                    <a>{authState.user?.name.split(" ")[0]}</a>
                  </Link>
                ) : authState.isUserLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span
                    onClick={() => dispatch(authActions.setIsModalOpen(true))}
                  >
                    Login/Register
                  </span>
                )}
              </div>

              <nav>
                <Accordion className={styles.accordion} defaultActiveKey="">
                  {menus.map(
                    (v, i) =>
                      !!v.children.length && (
                        <Accordion.Item
                          className={styles.accordion_item}
                          eventKey={i + "_" + v.name}
                        >
                          <Accordion.Header>
                            <Link href={v.url} passHref>
                              <a
                                target={v.target == "new" ? "_blank" : "_self"}
                                onClick={() => setNavOpen(!navOpen)}
                              >
                                {v.name}
                              </a>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body className={styles.accordion_body}>
                            <Accordion
                              className={styles.accordion}
                              defaultActiveKey=""
                            >
                              {v.children.map((x: any, i: any) =>
                                !!x.children.length ? (
                                  <Accordion.Item
                                    className={styles.accordion_item_secle}
                                    eventKey={x + "_" + x.name}
                                    key={x + "_" + x.name}
                                  >
                                    <Accordion.Header>
                                      <Link href={x.url} passHref>
                                        <a onClick={() => setNavOpen(!navOpen)}>
                                          {x.name}
                                        </a>
                                      </Link>
                                    </Accordion.Header>
                                    {/* {!!xv.children.length && ( */}
                                    <Accordion.Body
                                      className={styles.accordion_body}
                                    >
                                      <ul>
                                        {x.children.map((j: any, idx: any) => (
                                          <li key={idx}>
                                            <Link href={j.url} passHref>
                                              <a
                                                onClick={() =>
                                                  setNavOpen(!navOpen)
                                                }
                                              >
                                                {j.name}
                                              </a>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Accordion.Body>
                                    {/* )} */}
                                  </Accordion.Item>
                                ) : (
                                  <ul className="user_accordian">
                                    <li key={i + "_" + x.name}>
                                      <Link href={x.url} passHref>
                                        <a onClick={() => setNavOpen(!navOpen)}>
                                          {x.name}
                                        </a>
                                      </Link>
                                    </li>
                                  </ul>
                                )
                              )}
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                  )}
                </Accordion>

                <ul className={styles.nav_2}>
                  {menus.map(
                    (v, i) =>
                      v.children.length === 0 && (
                        <li key={i + "_" + v.name}>
                          <Link href={v.url} passHref>
                            <a
                              target={v.target == "new" ? "_blank" : "_self"}
                              onClick={() => setNavOpen(!navOpen)}
                              className={`${
                                v.name == "Sencoverse" ? "animation-text" : ""
                              }`}
                            >
                              {v.name}
                            </a>
                          </Link>
                        </li>
                      )
                  )}
                  {/* <li>
                    <a href="#">GIFTING</a>
                  </li>
                  <li>
                    <a href="#">COINS {"&"} BARS</a>
                  </li>
                  <li>
                    <a href="#">SENCO STORY</a>
                  </li>
                  <li>
                    <a href="#">SHOWROOMS</a>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div
              className={styles.overlay}
              onClick={() => setNavOpen(false)}
            ></div>
          </>
        )}
        {searchData && loadbox && (
          <div className={styles.suggestions}>
            <div className="row">
              <div className="col-md-12">
                {/* <div className={styles.suggestions_section_header}>
                        Did You Mean
                      </div>
                      <ul className={styles.border_contant}>
                        <li>
                          <a>Gold</a>
                        </li>
                        <li>
                          <a>Gold</a>
                        </li>
                      </ul> */}
                <div className={styles.suggestions_section_header}>
                  Categories
                </div>
                <ul>
                  {searchData &&
                    searchData?.categories?.map((v: any) => (
                      <>
                        <li
                          onClick={() => {
                            router.push({
                              pathname: `/jewellery/category/${
                                v.payload
                                  ?.find(
                                    (x: any) => x.key === "relatedCategories"
                                  )
                                  ?.value.reverse()[0]
                                  .url?.split("/jewellery/category/")[1]
                              }`,
                            });
                            setSearchData(null);
                            return;
                          }}
                        >
                          <a>
                            {" "}
                            <span>{v.value} </span>{" "}
                            <span className="span">
                              <Image src={searchicon} alt="" />{" "}
                            </span>
                          </a>
                        </li>
                      </>
                    ))}
                </ul>
                {searchData?.products && (
                  <>
                    <div className={styles.suggestions_section_header}>
                      Popular Products
                    </div>
                    <div className={styles.popular_products_search}>
                      <div className={styles.popular_products}>
                        <Swiper
                          className="swiper-navigation-black"
                          spaceBetween={10}
                          slidesPerView={2}
                          navigation={{
                            nextEl: ".image-swiper-button-next",
                            prevEl: ".image-swiper-button-prev",
                          }}
                        >
                          {searchData?.products?.result.map((v: any) => (
                            <>
                              <SwiperSlide>
                                <Link href={v.url} passHref>
                                  <a target="_blank" rel="noopener noreferrer">
                                    <div
                                      className={styles.popular_products_card}
                                    >
                                      <Image
                                        src={v?.mainImage}
                                        alt=""
                                        height={103}
                                        width={102}
                                      />
                                      <p>{v.name} </p>
                                      <p>
                                        {" "}
                                        <CurrencyInr value={v.sellingPrice} />
                                      </p>
                                    </div>
                                  </a>
                                </Link>
                              </SwiperSlide>
                            </>
                          ))}
                        </Swiper>
                      </div>

                      <button
                        role="button"
                        aria-label="button next"
                        className={`${
                          styles.arrow_left
                        } ${"image-swiper-button-next"}`}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                      <button
                        aria-label="button prev"
                        role="button"
                        className={`${
                          styles.arrow_right
                        } ${"image-swiper-button-prev"}`}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-8"></div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MobileHeader;
