import { menuList } from "@/data/menu";
import Link from "next/link";
import Image from "next/image";
import React, { Fragment, useEffect, useState } from "react";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import styles from "@/styles/navbar.module.scss";
type PageProps = {
  menus: any[];
};

const NavBar = ({ menus }: PageProps) => {

  return (
    <Fragment>
      <div className={`${styles.main_nav}`}>
        <nav className={styles.nav2}>
          <div className="container">
            <ul className={styles.main_menu}>
              {menus.map((v: any, i: any) => (
                <li className={styles.li} key={i + "_" + v.name}>
                  <Link href={v.url} passHref>
                    <a
                      className={`${
                        v.name == "Sencoverse" ? "animation-text" : ""
                      }`}
                      target={v.target == "new" ? "_blank" : "_self"}
                    >
                      {v.name}

                      {v.tags.includes("new") && (
                        <sup className="top_new">
                          <Image
                            src="https://sencowebfiles.s3.ap-south-1.amazonaws.com/menu-new.png"
                            alt="New"
                            layout="fill"
                            priority
                          />
                        </sup>
                      )}
                    </a>
                  </Link>
                  {!!v.children.length && v.type === "mega1" && (
                    <div className={styles.fw_dropdown}>
                      <div className={styles.nav_container}>
                        <div className={styles.dropdown_column_list_2}>
                          {v.children.map((j: any, idx: any) => (
                            <ul key={idx + "_" + j.name}>
                              <Link href={j.url} passHref>
                                <a>
                                  <div className={styles.dropdown_header_text}>
                                    {j.name}
                                  </div>
                                </a>
                              </Link>
                              {j.children.map((x: any, indxx: any) => (
                                <li key={indxx + "_" + x.name}>
                                  <Link href={x.url} passHref>
                                    <a className={styles.last_link}>{x.name}</a>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {!!v.children.length && v.type === "mega2" && (
                    <div className={styles.fw_dropdown}>
                      <div
                        className={`${styles.nav_container} ${styles.large_height_second_level}`}
                      >
                        <div className={styles.dropdown_column_list_2}>
                          <ul>
                            {v.children.map((j: any, idx: any) => (
                              <div key={idx + "_" + j.name}>
                                <Link href={j.url} passHref>
                                  <a>
                                    <div
                                      className={styles.dropdown_header_text}
                                    >
                                      {j.name}
                                    </div>
                                  </a>
                                </Link>
                                {j.children.map((x: any, indxx: any) => (
                                  <li key={indxx + "_" + x.name}>
                                    <Link href={x.url} passHref>
                                      <a className={styles.last_link}>
                                        {x.name}
                                      </a>
                                    </Link>
                                  </li>
                                ))}
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default NavBar;
