import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { wrapper } from "@/store";
import React, { Fragment, useEffect, useState } from "react";
import MiddleBar from "./middle-bar/MiddleBar";
import MobileHeader from "./mobile-header/MobileHeader";
import NavBar from "./nav-bar/NavBar";
import TopBar from "./top-bar/TopBar";
import styles from "@/styles/navbar.module.scss";

const Header = () => {
  const [data, setData] = useState<any>(
    [
      {
        url: "/jewellery/category/diamond-jewellery",
        name: "Diamond",
      },
      {
        url: "/jewellery/category/gold-jewellery",
        name: "Gold",
      },
      {
        url: "/jewellery/category/platinum-jewellery",
        name: "Platinum",
      },
      {
        url: "#",
        name: "Collections",
      },
      {
        url: "https://sencogoldanddiamonds.com/gifts/",
        name: "Gifts",
      },
      {
        url: "/gift-card",
        name: "Gift Cards",
      },
      {
        url: "/jewellery/category/coins-and-bars",
        name: "Coins, Bars & Beans",
      },
      {
        url: "https://everlite.com",
        name: "Everlite",
      },
      {
        url: "/house-of-senco",
        name: "House of Senco",
      },
      {
        url: "https://www.sencoverse.com",
        name: "Sencoverse",
      },
      {
        url: "/astro",
        name: "Astro",
      },
      {
        url: "#",
        name: "Others",
      },
    ].map((v) => ({
      tags: [],
      type: "link",
      target: "same",
      children: [],
      ...v,
    }))
  );
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    loadMenu();
  }, []);

  const loadMenu = async () => {
    const { data } = await httpClient.get(endpoints.home.menu());
    setData(data);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <header>
        <div className="d-none">
          <TopBar />
        </div>
        {/* <div className={isFixed ? `${styles.fixed} ${styles.w_100}` : ""}> */}
        <div className={`${styles.fixed} ${styles.w_100}`}>
          <MiddleBar />
          <NavBar menus={data} />
        </div>
        {/* </div> */}
        <div className={styles.nav_height}></div>
        {/* <div className={`${isFixed ? styles.fixe : "w-100"}`}></div> */}
        <MobileHeader menus={data} />
      </header>
    </Fragment>
  );
};

export default Header;
