import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import { selectCart } from "@/store/slices/cart";
import { selectWishlist } from "@/store/slices/wishlist";
import Link from "next/link";
import React, { Fragment } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import topBarStyles from "../TopBar.module.scss";

const RightLinks = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);
  const cartState = useSelector(selectCart);
  const wishlistState = useSelector(selectWishlist);

  return (
    <Fragment>
      <ul className={topBarStyles.nav_list}>
        {/* <CurrencyChanger /> */}
        <li className={topBarStyles.nav_item}>
          <Link href="/wishlist">
            <a>
              <i className="bi bi-heart"></i>
              {!!wishlistState.items.length && (
                <Badge
                  className={topBarStyles.cart_item}
                  pill
                  bg="light"
                  text="dark"
                >
                  {wishlistState.items.length}
                </Badge>
              )}
            </a>
          </Link>
        </li>
        <li className={topBarStyles.nav_item}>
          <Link href="/cart">
            <a>
              <i className="bi bi-cart"></i>
              {!!cartState.items.length && (
                <Badge
                  className={topBarStyles.cart_item}
                  pill
                  bg="light"
                  text="dark"
                >
                  {cartState.items.length}
                </Badge>
              )}
            </a>
          </Link>
        </li>
        {authState.user ? (
          <li className={topBarStyles.nav_item}>
            <Link href={"/account/profile"}>
              <a>
                <i className="bi bi-person-circle me-2"></i>
                {authState.user?.name.split(" ")[0]}
              </a>
            </Link>
          </li>
        ) : (
          <li className={topBarStyles.nav_item}>
            {authState.isUserLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span onClick={() => dispatch(authActions.setIsModalOpen(true))}>
                <i className="bi bi-person-circle me-2"></i>
                Login/Register
              </span>
            )}
          </li>
        )}
      </ul>
    </Fragment>
  );
};

export default RightLinks;
