import React, { Fragment } from "react";
import LeftLinks from "./left-links/LeftLinks";
import RightLinks from "./right-links/RightLinks";
import styles from "./TopBar.module.scss";
// import Marquee from "react-easy-marquee";

const TopBar = () => {
  return (
    <Fragment>
      <nav className={styles.nav}>
        {/* <Marquee duration={15000} height="30px" reverse>
          <p className="mb-0 top-text-topbar" style={{ color: "#FFFFFF" }}>
            Our website is currently undergoing maintenance. Some links might
            not work for the next 2-3 hours. If you experience any difficulties,
            please contact customer support. Thank you for your understanding.
          </p>
        </Marquee> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className={styles.topbar}>
                <LeftLinks />
                <RightLinks />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default TopBar;
