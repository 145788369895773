import { headerTopLeftLinks } from "@/data/header";
import Image from "next/image";
import React, { Fragment } from "react";
import topBarStyles from "../TopBar.module.scss";

const LeftLinks = () => {
  return (
    <Fragment>
      <ul className={topBarStyles.navlist1}>
        {headerTopLeftLinks.map((link, index) => (
          <li className={topBarStyles.navitem1} key={index}>
            <a {...link.props}>
              <Image src={link.image} alt={link.alt} priority unoptimized />
              <span>{link.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default LeftLinks;
