import { fbqTrackEvent } from "@/lib/fpixel";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import middleBarStyles from "../MiddleBar.module.scss";

const SearchBox = () => {
  const router = useRouter();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (router.query.q) {
      setSearch(router.query.q as string);
    } else {
      setSearch("");
    }
  }, [router.query]);

  const searchSubmit = (e: any) => {
    e.preventDefault();
    if (search.trim()) {
      fbqTrackEvent("Search", { search_string: search });
      router.replace(
        {
          pathname: "/jewellery",
          query: {
            q: search,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <Fragment>
      <div className={middleBarStyles.search}>
        <form className={middleBarStyles.search_form} onSubmit={searchSubmit}>
          <input
            type="text"
            placeholder="SEARCH"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            role="button"
            aria-label="Search button"
            className={middleBarStyles.search_button}
            type="submit"
          >
            <div className={middleBarStyles.icon}>
              <Image
                className="img-fluid"
                src={require("@/assets/images/search.png")}
                alt="alt"
                priority
                unoptimized
              />
            </div>
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default SearchBox;
